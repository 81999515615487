export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0];

export const dictionary = {
		"/(landing)": [7,[2]],
		"/(landing)/about": [8,[2]],
		"/(landing)/adspy": [9,[2]],
		"/auth/callback/reset-password": [~23,[3]],
		"/auth/forgot-password": [24,[3]],
		"/auth/signInWithProvider": [26,[3]],
		"/auth/signIn": [~25,[3]],
		"/auth/signOut": [27,[3]],
		"/auth/signUp": [~28,[3]],
		"/(landing)/blogs": [10,[2]],
		"/(landing)/blogs/[blog_number]": [11,[2]],
		"/(landing)/checkout/success": [~13,[2]],
		"/(landing)/checkout/[priceId]": [~12,[2]],
		"/(landing)/competitor-research": [14,[2]],
		"/hidden-path-29f1c64e87b0qz": [~29,[4]],
		"/(landing)/legal/privacy-policy": [15,[2]],
		"/(landing)/legal/terms-and-conditions": [16,[2]],
		"/(landing)/pricing": [~17,[2]],
		"/(landing)/pricing/one-day-plans": [~18,[2]],
		"/(landing)/product-database": [19,[2]],
		"/(landing)/sales-tracker": [20,[2]],
		"/(landing)/support": [21,[2]],
		"/tools/adspy": [30,[5]],
		"/tools/adspy/[ad_id]": [31,[5]],
		"/tools/blogs": [32,[5]],
		"/tools/competitor-research": [33,[5]],
		"/tools/competitor-research/[link]": [34,[5]],
		"/tools/dashboard": [35,[5]],
		"/tools/notifications": [36,[5]],
		"/tools/notifications/[notification_id]": [37,[5]],
		"/tools/product-database": [~38,[5]],
		"/tools/product-database/[product_id]": [39,[5]],
		"/tools/sales-analysis": [40,[5]],
		"/tools/sales-analysis/top-stores": [42,[5]],
		"/tools/sales-analysis/[store_domain]": [41,[5]],
		"/tools/sales-analytics": [43,[5]],
		"/tools/settings/billing": [~44,[5,6]],
		"/tools/settings/data": [~45,[5,6]],
		"/tools/settings/profile": [~46,[5,6]],
		"/tools/settings/security": [~47,[5,6]],
		"/(landing)/waitlist": [~22,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';